.treatmentContainer{
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-top: 6em;
   
}
.treatmentContainer h3{
    margin: 0;
}
.treatmeantShowcase{
 
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: fit-content;
    margin-bottom: 5em;
    margin: 0 auto;
}
.treatmeantShowcase img{
    width:250px;
    height: 150px;
    margin: 0.5em;
}


.treatMeantDescription{
    display: flex;
    width: 100%;
    gap: 20px;
}

.treatMeantDescriptionCol{
    display: flex;
    width: 80%;
    gap: 10px;
   margin-top:2em ;
    align-items: center;
}
.treatMeantDescriptionCol h3{
margin: 0;
}
.treatMeantDescriptionCol img{
    width: 120px;
    height: 100px;
}





@media (max-width:810px){
    .treatMeantDescription{
        flex-direction: column;
       
        align-items: center;
        gap: 0;
    }
  
}

@media (max-width:682px){
    .treatmeantShowcase{
        grid-template-columns: repeat(2,1fr);
    }
}

@media (max-width:485px){
    .treatmeantShowcase img{
        width:150px;
        height: 100px;
        margin: 0.2em;
    }
    .treatMeantDescription{
    
        position: relative;
       
    }
    .treatMeantDescriptionCol img{
        width: 70px;
        height: 60px;

    }

  
    .treatMeantDescriptionCol{
        font-size: 0.8rem;
        width: 100%;
    }
    .treatMeantDescriptionCol p{
        word-break: break-all;
    }
}