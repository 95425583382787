

.showCaseServiceContent {

    position: relative;
    width: 90%;
    margin: 0 auto;
    margin-top: 3em;
    display: flex;
    justify-content: space-between ;

    
  
}
.showCaseServiceImages img{
    width:200px;
    height: 130px;
    margin: 0.5em;
}
.showCaseServiceLeft{
   
    width: 50%;
}
.showCaseServiceLeft h1{
 
    word-break: break-all;
    margin: 0;
}
.showCaseServiceImages{

    width: 40%;
    
}


/*ani*/
@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

#showCase:target, #treatments:target, #massage:target, #product:target {
    animation: fadeInUp 1s ease-out forwards;
}

@media(max-width:650px){
    .showCaseServiceContent{
        flex-direction: column;
        justify-content: center;
    }

    .showCaseServiceLeft{
   
        width: 100%;
    }
    .showCaseServiceImages{
    
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
}