body{
    background-color: antiquewhite;
}
.navContainer{
    width: 90%;
  
    margin: 0 auto;

    background-color: transparent;

    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin-top: 0.5em;
 

}



/* Logo Starts here*/


.navLogo{
     width:250px;
    height:50px;
     display:flex;
   }
   
   .logoImage{
     position:relative;
    

   }
   .logoImage img{
     width:80px;
     height:68px;
   
   }
   
   
   .logoDescription{
     font-size:16.43px;
   
     display:flex;
     flex-direction:column;
   justify-content: space-around;
     color:#109490;
   }
   
   .logoDescription p {
     margin:0;
     padding:0;
   }
   
   .bottomLogoText {
     color:white;
   }


/*  Ends here*/


.Menus{
    display: none;
}
.navLinks ul{
    display: flex;
  
    gap: 34px;
    list-style: none;
    text-decoration: none;
    color: black;
}
.navLinks ul li {
    color: white;
}
.Nav-link {
    text-decoration: none;
    color: inherit;
  }

  .serviceLinks {
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 70%;
    width: 200px;
    background-color: darkcyan; 
   
    font-size: 1rem;
    z-index: 99;


  }
  
  .serviceItem {
  
    border-radius: 5px;
    display: flex;
 
    text-align: left;
    width: 100%;
}

.signOut{
  height: 23px;
  background-color: darkred;
  color: white;
  
  border: none;
}
@media (max-width:741px){
    .navLogo{
        width: 100%;
        display: flex;
        background-color: white;
        padding: 10px;
       
    }

    .logoImage img{
      width:60px;
      height:50px;
    
    }
    .logoDescription{
        font-size: 13px;
    }
    .bottomLogoText{
        color: black;
    }

    .navContainer{
     margin: 0;
     width: 100%;

   /* padding: 15px 15px 0px 15px;*/  
    }

    .Logo{

        width: 150px;
    }
    
    .Menus{
        position: absolute;
        display: block;
        width: 40px;
        cursor: pointer;
        right: 0.5em;
    }
    .navLinks{
   
        position: absolute;
        top: 100%;
        right: 0%;
  
        background-color: black;
        transition: right 0.3s ease-in-out;
        right: -110%;
        display: none;
        z-index: 99;
    }

    .openMenu{
        z-index: 99;
        right: 0%;
        display: block;
    }

    .serviceItem {
      margin: 0;
      padding: 0;
    }
    
    .openMenu .serviceLinks {
        display: flex; 
        flex-direction: column; 
        position: relative;
        width: 100%;
        align-items:flex-start;
        background-color: transparent;
        padding-top: 15px;
        
    }

    .navLinks ul{
        flex-direction: column;
        right: 0;
    }
}

