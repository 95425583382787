
.kontaktaOssContainer {
    position: absolute;
    top: 0;
    width: 100%;

    z-index: -2;
   
}

.kontaktaOssHeader {
    position: relative;
    width: 100%;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;

}

.kontaktaOssHeader img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -99;
}

.kontaktaOssHeaderdetails {
    position: absolute;
    width: 80%;
    color: white;
    font-size: 1.2rem;
    margin: 0 auto;
}




.kontaktaOssForm{

    margin: 0 auto;
    width: 80%;
}

.kontaktaOssForm h3{
    margin: 0;
    text-align: left;
    margin-top: 3em;
}



.KonatkaOssMessage-form {
    max-width: 400px;
    margin: 0 auto;

    
  }

  

  .KonatkaOssMessage-form input
 {
    width: 100%;
    padding: 8px;
    height: 40px;
    margin-top: 4px;
    box-sizing: border-box;
  }

  .KonatkaOssMessage-form  textarea 
  {
    width: 100%;
    padding: 8px;
    height: 70px;
    box-sizing: border-box;
  }
  
  

  
.KonatkaOssMessage-form button {
    background-color: black;
    color: #fff;
    padding: 10px;
    width:120px;
    border: none;
    cursor: pointer;
  }
  
