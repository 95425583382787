.masssageServiceContainer{
  
    width: 90%;
    margin: 0 auto;
 
    height: fit-content;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    margin-top: 5em;

    gap: 10px;
    
}


.massageCol{
    position: relative;
  
    color: white;
    height: 400px;
    overflow: hidden; 
    cursor: pointer; 
    transition: transform 0.3s ease; 
}

.massageDescription{


position: absolute;
bottom: 0;
 

transition: background-color 0.3s ease;
}

.massageDescription p{
    font-size: 1.4rem;
}
.massageDescription ul {
    font-size: 1.3rem;
}
.massageCol img{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    transition: transform 0.3s ease; 
  object-fit: cover; 
  object-position: center;

   
}

.massageCol:hover img {
    transform: scale(1.05); 
}

.massageCol:hover .massageDescription {
    background-color: rgba(0, 0, 0, 0.8);
}


@media (max-width:769px){
    .masssageServiceContainer{
        grid-template-columns: repeat(1,1fr);
    }
}
