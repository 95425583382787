
.spinner-container {
    position: relative;
    width: 100%;
    height: 500px;


  }
  
  .spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50px;
    height: 50px;
    border: 6px solid #3498db;
    border-top: 6px solid transparent;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: translate(-50%, -50%) rotate(0deg); }
    100% { transform: translate(-50%, -50%) rotate(360deg); }
  }
  

  @media (max-width: 600px) {
    
  
    .spinner {
      width: 25px;
      height: 25px;
      border-width: 3px;
    }
  }
  