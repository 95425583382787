
.slider-container{
    position: relative;
    height: 100vw;
    color: white;
    max-height: 720px;
 
}

.slides{

    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 0;
    justify-content: center;
   
}
.slides h1{
    margin: 0;
  
}

.slides p{
   padding: 0 15px 0 15px;
   font-size: 1.2rem;
}
.bokanNu{
    width: 120px;
    height: 34px;
    border: none;
    font-size: 1.2rem;
    background-color: darkred;
    color: white;
    cursor: pointer;

}


.sliderImg{
    position: absolute;
    width: 100%;
    height: 100%;
      z-index: -1;
}

.sliderImg img{
    
    object-fit: cover;
}

.slider-dots {
    position: absolute;
    bottom: 20px;
    width: 100%;
    text-align: center;
  }
  
  .dot {
    height: 15px;
    width: 15px;
    margin: 0 5px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    cursor: pointer;
  }
  
  .dot.active {
    background-color: darkblue;
  }