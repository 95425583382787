.productContainer{
    width: 90%;
    margin: 0 auto;
    display: grid;
    text-align: center;
    margin-top: 2em;
    margin-bottom: 5em;

}


.productImg img {
    width: 260px;
    height: 240px;
    object-fit:cover;
    object-position: left;
}

.productImg{

    display: grid;
    gap: 10px;
    grid-template-columns: repeat(4,1fr);
}


@media (max-width: 1048px){
    .productImg{
        grid-template-columns: repeat(3,1fr);
    }
}

@media (max-width:780px){
    .productImg{
        grid-template-columns: repeat(2,1fr);
    }
}

@media (max-width:590px){
    .productImg img{
       width: 200px;
       height:100px ;
    }
}


@media (max-width:450px){
    .productImg img{
        width: 100%;
        height: 100%;
    }
    .productImg {
       
        grid-template-columns: repeat(1,1fr);
    }
}