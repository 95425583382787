
/*Design needs some documentation*/
.omOssContainer {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100vw;
    z-index: -2;
   
}

.omOssHeader {
    position: relative;

    width: 100%;
    height: 430px;
    display: flex;
    justify-content: center;
    
    align-items: center;

}

.omOssHeader img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: bottom;
    z-index: -99;
}

.omOssdetails {
    position: absolute;
    width: 80%;
    color: white;
    font-size: 1.2rem;
    margin: 0 auto;
}


.omOssServiceContainer{

    display: flex;
    width: 90%;
    margin: 0 auto;
    gap: 10px;
    margin-top: 3em;
}
.omOssImageContent img{
    width: 420px;
    height: 350px;
}

.omOssImageContent {

  
    
}

.omOssDescription {
  
}

.omOssDescription h3{
    font-size: 1.2rem;
}




.showcase-container {
    text-align: center;
    padding: 50px;
    margin-top: 2em;
    height: 300px; 
  }
  
  .company-name {
    font-size: 36px;
    font-style: italic;
    margin-bottom: 20px;
  }
  
  .showcasetitle {
    font-size: 28px;
    margin-bottom: 20px;
  }
  
  .showcasemessage {
    font-size: 18px;
  }

/* Three divs stytle*/

.three-divs-container {
    position: relative;
    display: grid;

    width: 90%;
    margin: 0 auto;
    grid-template-columns: repeat(3,1fr);
  }
  
  .three-divs-content-div {
    width: 300px;
    margin: 10px;
    text-align: center;
    padding: 20px;
    background-color: #f8f8f8;
  }
  
  .three-divs-image {
    width: 100%;
    max-height: 150px; 
    object-fit: cover;
    border-radius: 8px;
  }
  
  .three-divs-title {
    font-size: 20px;
    margin: 10px 0;
  }
  
  .three-divs-description {
    font-size: 16px;
  }

@media (max-width:1125px){
    .three-divs-container{
        grid-template-columns: repeat(2,1fr);
    }
}
@media (max-width: 808px) {
    .omOssImageContent {

        text-align: center; 
         width: 100%;
    }
    .three-divs-content-div{
        width: 250px;
    }
    .omOssImageContent img{
        width: 320px;
        height: 230px;
    }
    
    .omOssdetails {
        width: 90%;
    }
   
    .omOssServiceContainer {
        flex-direction: column;
        align-items: flex-start; 
    }
    .omOssServiceContainer  h3{
        text-align: center;
    }
}

@media (max-width: 600px){
    .three-divs-container{
        grid-template-columns: repeat(1,1fr);

      
    }

    .three-divs-content-div{
        margin: 0 auto;
        margin-top: 0.3em;
    }
} 