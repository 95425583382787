
/*Design needs some documentation*/
.serviceContainer {
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -2;
   
}

.serviceHeader {
    position: relative;
   
    width: 100%;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.serviceHeader img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;

    z-index: -99;
}

.details {
    position: absolute;
    width: 80%;
    color: white;
    font-size: 1.2rem;
    margin: 0 auto;
}

.servicePicture {
    width: 395px;
    height: 250px;
    object-fit: cover;
}

.servicePictureMain{
    width: 100%;

display: flex;
justify-content: center;


}
.timeBooking h2 {
    margin: 0;
}

.timeBooking {
    width: 300px;
    font-size: 1.1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 9px;
}

.timeSheet {
    display: flex;
    flex-direction: column;
    text-align: left;
    align-items: flex-start; 
}

.serviceContent {
    display: flex;
    width: 90%;
    margin: 0 auto;
    gap: 30px;
}





.contentRight {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

@media (max-width: 808px) {
    .servicePicture{
        width: 300px;
    }
    .serviceContent {
        flex-direction: column;
        align-items: flex-start; 
    }
    .contentRight {
     
        text-align: left; 
    }
    .details {
        width: 90%;
    }
    .timeSheet {
        display: flex;
        flex-direction: column;
        text-align: left;
        align-items: flex-start; 
    }
}
